import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import firebase from '../../firebase';
import Page from '../../components/Page';
import Header from '../../components/Header';
import styles from './styles';
import Footer from '../../components/Footer';

class About extends React.PureComponent {
    componentDidMount(){
        firebase.analytics().logEvent('page_view', {
            page_title: 'About',
            // eslint-disable-next-line no-restricted-globals
            page_location: location.href,
            // eslint-disable-next-line no-restricted-globals
            page_path: location.pathname,
        });
    }

    render() {
        const {
            managedClasses,
        } = this.props;

        return (
            <Page>
                <Header />
                <div className={managedClasses.pageContent}>
                    <h1 className={managedClasses.title}>
                        About
                    </h1>
                    <div className={managedClasses.entry} >
                        {/* <div className={managedClasses.question}>
                            Why another homebrewing app?
                        </div> */}
                        <div className={managedClasses.answer}>
                            My name is <a href="https://codysorgenfrey.com" rel="noreferrer" target="_blank">Cody Sorgenfrey</a>. I’m a UX designer at Microsoft based in Seattle, WA. After being introduced to the wonderful world of homebrewing by my next-door neighbor; I immediately became obsessed. I invested all my free time and money into the hobby. I quickly became dissatisfied with the state of the tools that homebrewers have at their disposal. Most of the apps are old and clunky looking, some of them are expensive and don’t provide the modern-day amenities that one would expect from a mobile app such as storing recipes in the cloud or sharing and collaboration with other&nbsp;brewers. 
                            <br /><br /><b>I had finally had&nbsp;enough.</b><br /><br />
                            I decided that although it would take a significant amount of time and energy, the community needed a better homebrewing app. One with accurate calculations and modern technologies, that didn’t get in the way on the big day. That’s when the name hit me. Brewday. It’d be all about making the big day as fluid and stress free as&nbsp;possible. 
                            <br /><br />I hope you enjoy using Brewday as much as I do. If you have a comment or suggestion, <a href="mailto:support@brewday.co?subject=Feature suggestion">please let me&nbsp;know.</a>
                        </div>
                    </div>
                </div>
                <Footer />
            </Page>
        );
    }
};

About.propTypes = {
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default manageJss(styles)(About);
