import { 
    body, 
    bodyBold, 
    caption, 
    cardFloating, 
    headingBold, 
    light, 
    subHeadingBold, 
    water,
} from '../../designSystem';
import heroImage from '../../images/Web_Hero_01.jpg';
import organizedImage from '../../images/Web_Organized_01.png';

export default {
    slideInPre: {
        transform: 'translateY(-24px)',
        opacity: 0,
        transitionProperty: 'transform, opacity',
        transitionDuration: '300ms',
        transitionTimingFunction: 'cubic-bezier(0,0,0,1)',
        transitionDelay: '150ms',
    },
    slideIn: {
        transform: 'translateY(0px)',
        opacity: 1,
    },
    hero: {
        width: 1440,
        height: 810,
        background: `url(${heroImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    title: {
        composes: '$slideInPre',
        width: 'calc(100vw - 112px)',
        maxWidth: 768-112,
        padding: '56px 56px 144px 56px',
    },
    headline: {
        ...headingBold(),
        marginBottom: 8,
    },
    tagline: {
        ...body(),
    },
    featureHeadline: {
        ...subHeadingBold(),
        marginBottom: 8,
    },
    featureHeadlineLight: {
        ...subHeadingBold(),
        marginBottom: 8,
        color: light,
    },
    featureTagline: {
        ...body(),
    },
    featureTaglineLight: {
        ...body(),
        color: light,
    },
    section: {
        width: '100vw',
        maxWidth: 768,
        height: '56.25vw', // 16x9
        maxHeight: 432,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
    },
    organized: {
        composes: '$section',
        background: `url(${organizedImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
        padding: '128px 64px 0px 64px',
        margin: '-128px -64px 0px -64px',
    },
    half: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    halfTitle: {
        composes: '$half',
    },
    feature: {
        composes: '$slideInPre',
        maxWidth: 256,
    },
    blueWrapper: {
        backgroundColor: water,
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    whiteWrapper: {
        composes: '$blueWrapper',
        backgroundColor: light,
    },
    flexibleImage: {
        alignSelf: 'flex-start',
        width: 'calc(100% - 80px)',
        marginTop: 40,
        ...cardFloating(),
        borderRadius: 32,
        maxWidth: 375,
    },
    social: {
        composes: '$section',
        overflow: 'hidden',
        padding: '128px 128px 0px 128px',
        margin: '-128px -128px 0px -128px',
        '& > :first-child': {
            overflow: 'visible',
        },
    },
    socialImage: {
        margin: '0px -128px',
    },
    cardContainer: {
        composes: '$section',
        display: 'block',
        height: 'min-content',
        maxHeight: 'none',
        width: 'calc(100% - 64px)',
        padding: 32,
        columnCount: 3,
        columnGap: 0,
    },
    card: {
        composes: '$slideInPre',
        maxWidth: 256,
        minWidth: 160,
        padding: 16,
        breakInside: 'avoid',
    },
    cardBody: {
        ...caption(),
    },
    cardTitle: {
        ...bodyBold(),
        marginBottom: 16,
    },
    footer: {
        maxWidth: 768,
    },
    '@media (max-width: 647px)': {
        hero: {
            width: 960,
            height: 540,
            marginBottom: 96,
        },
        title: {
            width: 'calc(100vw - 48px)',
            padding: '64px 24px',
        },
        section: {
            flexDirection: 'column',
            height: 540,
            maxHeight: 'none',
        },
        halfTitle: {
            flex: 0.5,
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        feature: {
            padding: 24,
        },
        organized: {
            backgroundSize: '200%',
            backgroundPosition: '0% 50%',
        },
        flexibleImage: {
            width: 'calc(100% - 48px)',
            marginTop: 0,
        },
        social: {
            flexDirection: 'column-reverse',
            '& > :first-child': {
                overflow: 'hidden',
            },
        },
        socialImage: {
            marginTop: 200,
            marginLeft: -64,
            transform: 'rotate(30deg)',
        },
        cardContainer: {
            columnCount: 'unset',
            columnGap: 'unset',
            padding: '16px 8px',
            width: 'calc(100vw - 16px)',
            height: 'min-content',
        },
        card: {
            breakInside: 'unset',
        },
    },
    '@media (min-width: 479px) and (max-width: 647px)': {
        cardContainer: {
            columnCount: 2,
        }
    },
};