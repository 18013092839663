import React from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    withRouter,
} from "react-router-dom";
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import Notify from './pages/Notify';
import MobileNav from './pages/MobileNav';
import About from './pages/About';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Cookies from './pages/Cookies';

class Routes extends React.PureComponent {
    render() {
        const {
            location,
        } = this.props;

        const background = location.state && location.state.background;

        return (
            <div>
                <Switch location={background || location}>
                    <Route path="/faq">
                        <FAQ />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/terms">
                        <Terms />
                    </Route>
                    <Route path="/privacy">
                        <Privacy />
                    </Route>
                    <Route path="/cookies">
                        <Cookies />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>

                {/* Show the modal when a background page is set */}
                {background && (
                    <Switch>
                        <Route path="/notify">
                            <Notify />
                        </Route>
                        <Route path="/nav">
                            <MobileNav />
                        </Route>
                    </Switch>
                )}
            </div>
        );
    }
}

Routes.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(Routes);
