import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import styles from './styles';

class TextInput extends React.PureComponent {
    render() {
        const {
            className,
            managedClasses,
            onchange,
            placeholder,
            type,
            value,
        } = this.props;

        return (
            <input
                className={`${managedClasses.input} ${className}`}
                onChange={onchange}
                placeholder={placeholder}
                type={type}
                value={value}
            />
        );
    }
};

TextInput.propTypes = {
    className: PropTypes.string,
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
    onchange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
};

TextInput.defaultProps = {
    className: '',
    placeholder: '',
    type: 'text',
    value: '',
};

export default manageJss(styles)(TextInput);
