import { DesignSystemDefaults } from '@microsoft/fast-components-styles-msft';

export const designSystemLight = {
    ...DesignSystemDefaults,
};

export const designSystemDark = {
    ...designSystemLight,
};

// colors
export const offWhite = () => ('#F6F7F8');
export const light = () => ('#FFFFFF');
export const acrylic = () => ({
    backgroundColor: 'rgba(255,255,255,0.6)',
    backdropFilter: 'blur(32px)',
    WebkitBackdropFilter: 'blur(32px)',
});
export const dark = () => ('#333333');
export const medium = () => ('#999999');
export const strawberry = () => ('#F4592A');
export const water = () => ('#00C8FF');

// type
export const defaultTypeStyle = () => ({
    fontFamily: 'neue-haas-grotesk-display, sans-serif',
    fontStyle: 'normal',
    color: dark,
});
export const heading = () => ({
    ...defaultTypeStyle(),
    fontWeight: '500',
    fontSize: '47px',
    lineHeight: '48px',
    letterSpacing: '-0.015em',
});
export const headingBold = () => ({
    ...heading(),
    fontWeight: '600',
});
export const subHeading = () => ({
    ...defaultTypeStyle(),
    fontWeight: '500',
    fontSize: '29px',
    lineHeight: '32px',
});
export const subHeadingBold = () => ({
    ...subHeading(),
    fontWeight: '600',
});
export const body = () => ({
    ...defaultTypeStyle(),
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.015em',
});
export const bodyBold = () => ({
    ...body(),
    fontWeight: '600',
});
export const caption = () => ({
    ...defaultTypeStyle(),
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.05em',
});
export const captionBold = () => ({
    ...caption(),
    fontWeight: '600',
});
export const legal = () => ({
    ...defaultTypeStyle(),
    fontWeight: '500',
    fontSize: '8px',
    lineHeight: '12px',
    letterSpacing: '0.085em',
});
export const legalBold = () => ({
    ...legal(),
    fontWeight: '600',
});

// elevation
export const cardRest = () => ({
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 8px 16px rgba(0, 0, 0, 0.08)',
});
export const cardFloating = () => ({
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.16)',
});
export const createButton = () => ({
    boxShadow: '0px 4px 12px rgba(255, 61, 2, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.16)',
});