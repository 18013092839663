import {
    bodyBold,
    cardRest,
    dark,
    water,
} from '../../designSystem';

export default {
    gw: {
        transition: 'all cubic-bezier(.5,.5,.4,1) 150ms',
        fill: dark,
        border: '2px solid transparent',
        outline: 'none',
        background: 'none',
        padding: 0,
        margin: 0,
        ...bodyBold(),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            ...cardRest(),
        },
        '&:focus-visible': {
            border: `2px solid ${water()}`,
        },
    },
    clear: {
        composes: '$gw',
        '&:hover': {
            boxShadow: 'none',
        },
    },
};                              