import React from 'react';
import { DesignSystemProvider } from '@microsoft/fast-jss-manager-react';
import SunCalc from 'suncalc';
import { BrowserRouter as Router } from "react-router-dom";
import firebase from './firebase';
import {
    designSystemDark,
    designSystemLight,
} from './designSystem';
import Routes from './routes';

class App extends React.PureComponent {
    componentDidMount() {
        // start analytics
        firebase.analytics();
    }

    calcDesignSystem = (config) => {
        let theme = designSystemDark;
        if (config === 'light') theme = designSystemLight;
        if (config === 'auto') {
            const d = new Date();
            const t = SunCalc.getTimes(d, navigator.geolocation.latitude, navigator.geolocation.longitude);
            if (d.getTime() > t.sunrise.getTime() && d.getTime() < t.sunset.getTime()) {
                theme = designSystemLight;
            }
        }
        return theme;
    }

    render() {
        return (
            <DesignSystemProvider
                designSystem={this.calcDesignSystem('light')}
            >
                <Router>
                    <Routes />
                </Router>
            </DesignSystemProvider>
        );
    }
}

export default App;
