import { 
    bodyBold, 
    headingBold, 
    medium, 
    subHeadingBold, 
    water,
} from "../../designSystem";

export default {
    pageContent: {
        padding: 32,
        width: 'calc(100% - 64px)',
        maxWidth: 512,
    },
    title: {
        ...headingBold(),
        margin: 0,
        width: '100%',
    },
    entry: {
        maxWidth: 512,
        marginTop: 32,
    },
    question: {
        ...bodyBold(),
        marginBottom: 8,
    },
    answer: {
        '& a': {
            textDecoration: 'none',
            color: water,
        },
    },
    inlineLinks: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: 8,
        },
    },
    help: {
        composes: '$entry',
        width: '100%',
        '& > :first-child': {
            ...subHeadingBold(),
            color: medium,
        },
    },
};