import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import { withRouter } from 'react-router-dom';
import styles from './styles';
import GlyphWrapper from '../GlyphWrapper';
import icons from '../../icons';

class Modal extends React.Component {
    componentDidMount() {
        // animate in
        setTimeout(() => {
            this.showModal();
        }, 1);
    }

    showModal = () => {
        const {
            managedClasses,
        } = this.props;

        this.smokeRef.classList.add(managedClasses.smokeIn);
        this.modalRef.classList.add(managedClasses.modalIn);
    }

    hideModal = () => {
        const {
            history,
            managedClasses,
        } = this.props;

        this.smokeRef.classList.add(managedClasses.smokeOut);
        this.modalRef.classList.add(managedClasses.modalOut);
        setTimeout(() => {
            history.goBack();
        }, 150);
    }

    render() {
        const {
            children,
            managedClasses,
        } = this.props;

        return (
            <div>
                <div 
                    ref={ref => { this.smokeRef = ref; }}
                    className={managedClasses.smoke} 
                    onClick={this.hideModal}
                />
                <div
                    ref={ref => { this.modalRef = ref; }} 
                    className={managedClasses.modal}
                >
                    <GlyphWrapper
                        className={managedClasses.close}
                        onClick={this.hideModal}
                    >
                        {icons[24].close}
                    </GlyphWrapper>
                    <div className={managedClasses.body}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
};

Modal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

Modal.defaultProps = {
    children: {},
};

export default withRouter(manageJss(styles)(Modal));
