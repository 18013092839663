import { 
    medium, 
    water 
} from "../../designSystem";

export default {
    '@keyframes spin': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
    spinner: {
        animation: 'spin 1200ms linear infinite',
        width: 16,
        height: 16,
        borderRadius: '50%',
        border: `4px solid ${medium()}`,
        borderTop: `4px solid ${water()}`,
    },
};