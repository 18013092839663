import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import { Link, withRouter } from 'react-router-dom';
import firebase from '../../firebase';
import Page from '../../components/Page';
import Header from '../../components/Header';
import styles from './styles';
import Footer from '../../components/Footer';

class Home extends React.PureComponent {
    componentDidMount(){
        firebase.analytics().logEvent('page_view', {
            page_title: 'Frequently Asked Questions',
            // eslint-disable-next-line no-restricted-globals
            page_location: location.href,
            // eslint-disable-next-line no-restricted-globals
            page_path: location.pathname,
        });
    }

    render() {
        const {
            managedClasses,
            location,
        } = this.props;

        return (
            <Page>
                <Header />
                <div className={managedClasses.pageContent}>
                    <h1 className={managedClasses.title}>
                        Frequently<br />
                        Asked<br />
                        Questions
                    </h1>
                    <div className={managedClasses.cards}>
                        <div className={managedClasses.entry}>
                            <div className={managedClasses.question}>
                                What is Brewday?
                            </div>
                            <div className={managedClasses.answer}>
                                Brewday is a complete suite for designing and brewing beer at any scale. Design award winning recipes from scratch or try a popular recipe from the community. Keep everything organized on your brew day and beyond with step-by-step instructions, measurements, and notes. Be consistent every time you brew and rest assured that you didn’t forget a&nbsp;thing.
                            </div>
                        </div>
                        <div className={managedClasses.entry}>
                            <div className={managedClasses.question}>
                                When will Brewday be&nbsp;ready?
                            </div>
                            <div className={managedClasses.answer}>
                                We are currently working hard on making Brewday a reality. There is a lot of work that goes into developing an app of this quality and scale and we are just&nbsp;beginning!<br />
                                <Link 
                                    to={{ 
                                        pathname: '/notify',
                                        state: { background: location },
                                    }}
                                >
                                    Sign up to receive updates on the&nbsp;progress.
                                </Link>
                            </div>
                        </div>
                        <div className={managedClasses.entry}>
                            <div className={managedClasses.question}>
                                What will Brewday&nbsp;cost?
                            </div>
                            <div className={managedClasses.answer}>
                                We want Brewday to be accessible to everyone. Brewing already has such a steep learning curve and we do not want to be a barrier to anyone trying to get started. For that reason, it will be free to use for most hobbyists. However, there is real cost in developing and hosting an app. We will do our best to price Brewday fairly and&nbsp;appropriately.
                            </div>
                        </div>
                        <div className={managedClasses.entry}>
                            <div className={managedClasses.question}>
                                How can I find out more about&nbsp;Brewday?
                            </div>
                            <div className={managedClasses.answer}>
                                <Link 
                                    to={{ 
                                        pathname: '/notify',
                                        state: { background: location },
                                    }}
                                >
                                    Sign up to receive updates and news about&nbsp;Brewday.
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={managedClasses.help}>
                        <div className={managedClasses.question}>
                            Don&apos;t see your&nbsp;question?
                        </div>
                        <div className={managedClasses.answer}>
                            <a href="mailto:support@brewday.co">Contact&nbsp;us.</a>
                        </div>
                    </div>
                </div>
                <Footer />
            </Page>
        );
    }
};

Home.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(manageJss(styles)(Home));
