import { bodyBold, captionBold, dark, medium, subHeadingBold } from "../../designSystem";

export default {
    mainNav: {
        ...bodyBold(),
        color: dark,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 16,
        '& > *': {
            marginBottom: 16,
        },
    },
    secNav: {
        flex: 1,
        ...captionBold(),
        color: medium,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        '& > *': {
            marginBottom: 8,
        },
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
    },
    logo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 32,
    },
    wordmark: {
        ...subHeadingBold(),
        marginLeft: 16,
    }
};
