import { bodyBold, headingBold, water } from "../../designSystem";

export default {
    pageContent: {
        padding: 32,
        width: 'calc(100% - 64px)',
        maxWidth: 512,
    },
    title: {
        ...headingBold(),
        margin: 0,
    },
    entry: {
        marginTop: 32,
        maxWidth: 512,
    },
    answer: {
        lineHeight: '32px',
        '& a': {
            textDecoration: 'none',
            color: water,
        },
        '& b': {
            ...bodyBold(),
        },
    },
};