import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import firebase from '../../firebase';
import styles from './styles';
import ActionButton from '../../components/ActionButton';
import TextInput from '../../components/TextInput';
import LoadingSpinner from '../../components/LoadingSpinner';
import Modal from '../../components/Modal';

class NotifyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            status: 'init',
        };
    }

    componentDidMount(){
        firebase.analytics().logEvent('page_view', {
            page_title: 'Notify Me',
            // eslint-disable-next-line no-restricted-globals
            page_location: location.href,
            // eslint-disable-next-line no-restricted-globals
            page_path: location.pathname,
        });
    }

    addEmail = async e => {
        const {
            email,
        } = this.state;

        // validate email
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const search = email.search(emailRegex);
        if (search === -1) { // doesn't match
            this.setState({ status: 'invalid' });
            return;
        }

        // add to mailchimp
        this.setState({ status: 'loading' });
        const notifyMe = firebase.functions().httpsCallable('notifyMe');
        const analytics = firebase.analytics();
        notifyMe({ email })
            .then(res => {
                if (res.data.status === 'ok'){
                    this.setState({ status: 'success'});
                    analytics.logEvent('newsletter_signup');
                } else {
                    this.setState({ status: 'error' });
                    console.log('Error: ', res.data.status);
                }
            })
            .catch(error => {
                this.setState({ status: 'error'});
                console.log('Error: ', error);
            });
    }

    render() {
        const {
            email,
            status,
        } = this.state;

        const {
            managedClasses,
        } = this.props;

        return (
            <Modal>
                <div className={managedClasses.desc}>
                    <div className={managedClasses.title}>
                        {(status !== 'success') && (
                            <span>We&apos;ll let you know</span>
                        )}
                        {(status === 'success') && (
                            <span>Thanks!</span>
                        )}
                    </div>
                    <div className={managedClasses.body}>
                        {(status !== 'success' && status !== 'exists') && (
                            <span>We&apos;re currently working hard on making Brewday a reality. When it&apos;s ready for a spin we&apos;ll let you know!</span>
                        )}
                        {(status === 'success') && (
                            <span>We&apos;ll be in touch when there&apos;s news to share!</span>
                        )}
                        {(status === 'exists') && (
                            <span>You&apos;re already on the list.</span>
                        )}
                    </div>
                </div>
                {(status === 'invalid' || status === 'error') && (
                    <div className={managedClasses.error}>
                        {status === 'invalid' && (
                            <span>Please enter a valid email.</span>
                        )}
                        {status === 'error' && (
                            <span>An error has occurred. Please try again later.</span>
                        )}
                    </div>
                )}
                {(status !== 'success' && status !== 'exists') && (
                    <form
                        className={managedClasses.form}
                        onSubmit={e => {
                            e.preventDefault();
                            this.addEmail();
                        }}
                    >
                        <TextInput
                            className={managedClasses.textInput}
                            onchange={e => {
                                this.setState({ email: e.target.value });
                            }}
                            onsub
                            placeholder="Email"
                            type="email"
                            value={email}
                        />
                        <div className={managedClasses.buttonWithSpinner}>
                            <ActionButton
                                onClick={this.addEmail}
                                theme="secondary"
                            >
                                Notify me
                            </ActionButton>
                            {(status === 'loading') && (
                                <LoadingSpinner className={managedClasses.spinner} />
                            )}
                        </div>
                    </form>
                )}
            </Modal>
        );
    }
};

NotifyModal.propTypes = {
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default manageJss(styles)(NotifyModal);
