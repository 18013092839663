import {
    body,
    cardFloating,
    dark,
    light,
} from '../../designSystem';

export default {
    smoke: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0)',
        cursor: 'pointer',
    },
    smokeIn: {
        transition: 'background-color 150ms linear',
        backgroundColor: 'rgba(0,0,0,0.2)',
    },
    smokeOut: {
        transition: 'background-color 150ms linear',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    modal: {
        transform: 'translateY(-24px)',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        top: 32,
        right: 32,
        backgroundColor: light,
        ...cardFloating(),
        padding: 32,
        borderRadius: 16,
        width: 320,
        ...body(),
        color: dark,
        opacity: 0,
        zIndex: 1000,
    },
    modalIn: {
        transition: 'all 300ms cubic-bezier(0,0,0,1)',
        transform: 'translateY(0px)',
        opacity: 1,
    },
    modalOut: {
        transition: 'all 150ms cubic-bezier(.33,0,0,0)',
        transform: 'translateY(24px)',
        opacity: 0,
    },
    close: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
    body: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    '@media (max-width: 539px)': {
        modal: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: 'auto',
            borderRadius: 0,
            padding: 24,
        },
    },
};
