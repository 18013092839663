import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import styles from './styles';

class Page extends React.PureComponent {
    render() {
        const {
            managedClasses,
            children,
        } = this.props;

        return (
            <div className={managedClasses.page} id="page">
                {children}
            </div>
        );
    }
};

Page.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

Page.defaultProps = {
    children: {},
};

export default manageJss(styles)(Page);
