import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import firebase from '../../firebase';
import Page from '../../components/Page';
import Header from '../../components/Header';
import styles from './styles';
import flexibleImage from '../../images/Web_Flexible_01.jpg';
import socialImage from '../../images/Web_Social_01.png';
import Footer from '../../components/Footer';

require('uikit');

class Home extends React.PureComponent {
    componentDidMount(){
        firebase.analytics().logEvent('page_view', {
            page_title: 'Home',
            // eslint-disable-next-line no-restricted-globals
            page_location: location.href,
            // eslint-disable-next-line no-restricted-globals
            page_path: location.pathname,
        });
    }

    render() {
        const {
            managedClasses,
        } = this.props;

        const cardList = [
            {
                title: <span>Smart recipe builder</span>,
                body: <span>Build award-winning recipes with the ingredients you love. Easy to use recipe builder shows you the info you need when you need it. Scale recipes with ease by volume, OG or&nbsp;efficiency.</span>,
                key: 'recipes',
            },
            {
                title: <span>Cloud based</span>,
                body: <span>Integrate Brewday into your brewery knowing it can handle all you have to throw at it. All your data is stored safely and securely with state-of-the-art web&nbsp;technology.</span>,
                key: 'unlimited',
            },
            {
                title: <span>Inline calibration adjustments</span>,
                body: <span>Never forget a measurement trying to calibrate or convert units again. Tell Brewday what the instrument reads, and we’ll calibrate and convert for&nbsp;you.</span>,
                key: 'adjust',
            },
            {
                title: <span>Works on all your favorite&nbsp;devices</span>,
                body: <span>Brewday is built on modern web technologies that enable you to install anywhere, work offline, and keep everything stored in the cloud for safe&nbsp;keeping.</span>,
                key: 'all devices',
            },
            {
                title: <span>Customizable dashboard</span>,
                body: <span>See what’s important to you immediately. Continue where you left off with that recipe or catch up on what your friends and colleagues are&nbsp;brewing.</span>,
                key: 'dashboard',
            },
            {
                title: <span>Import from Beersmith</span>,
                body: <span>Leave the old way behind without missing a beat. Bring all your recipes over from&nbsp;Beersmith.</span>,
                key: 'beersmith',
            },
        ];

        return (
            <Page>
                <Header background={false} showLogo={false} />
                <div className={managedClasses.hero}>
                    <div className={managedClasses.title} data-uk-scrollspy={`cls:${managedClasses.slideIn};`}>
                        <div className={managedClasses.headline}>
                            Brewday
                        </div>
                        <div className={managedClasses.tagline}>
                            The brewing&nbsp;app for&nbsp;everyone.
                        </div>
                    </div>
                </div>
                <div className={managedClasses.organized}>
                    <div className={managedClasses.half} />
                    <div className={managedClasses.halfTitle}>
                        <div className={managedClasses.feature} data-uk-scrollspy={`cls:${managedClasses.slideIn}; delay: 300;`}>
                            <div className={managedClasses.featureHeadline}>
                                Organized
                            </div>
                            <div className={managedClasses.featureTagline}>
                                The right info for what you’re doing one step at a&nbsp;time.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={managedClasses.blueWrapper}>
                    <div className={managedClasses.section}>
                        <div className={managedClasses.halfTitle}>
                            <div className={managedClasses.feature} data-uk-scrollspy={`cls:${managedClasses.slideIn};`}>
                                <div className={managedClasses.featureHeadlineLight}>
                                    Flexible
                                </div>
                                <div className={managedClasses.featureTaglineLight}>
                                    Fits into your workflow from planning to&nbsp;packaging.
                                </div>
                            </div>
                        </div>
                        <div className={managedClasses.half}>
                            <img
                                alt="App showing multiple steps being created."
                                className={managedClasses.flexibleImage}
                                src={flexibleImage}
                            />
                        </div>
                    </div>
                </div>
                <div className={managedClasses.whiteWrapper}>
                    <div className={managedClasses.social}>
                        <div className={managedClasses.half}> 
                            <img
                                alt="App showing a recipe on multiple devices."
                                className={managedClasses.socialImage}
                                height={612}
                                src={socialImage}
                                width={582}
                            />
                        </div>
                        <div className={managedClasses.halfTitle}>
                            <div className={managedClasses.feature} data-uk-scrollspy={`cls:${managedClasses.slideIn};`}>
                                <div className={managedClasses.featureHeadline}>Social</div>
                                <div className={managedClasses.featureTagline}>
                                    Share recipes, collect ratings follow your favorite&nbsp;brewers.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={managedClasses.cardContainer} data-uk-scrollspy={`target: > div; cls:${managedClasses.slideIn}; delay: 150;`}>
                    {cardList.map(item => (
                        <div
                            key={item.key}
                            className={managedClasses.card}
                        >
                            <div className={managedClasses.cardTitle}>
                                {item.title}
                            </div>
                            <div className={managedClasses.cardBody}>
                                {item.body}
                            </div>
                        </div>
                    ))}
                </div>
                <Footer className={managedClasses.footer} />
            </Page>
        );
    }
};

Home.propTypes = {
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default manageJss(styles)(Home);
