import { 
    dark, 
    light, 
    subHeadingBold, 
} from "../../designSystem";

export default {
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
    content: {
        width: 'calc(100% - 64px)',
        maxWidth: 1440-64,
        margin: 'auto',
        color: dark,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 32,
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
    },
    wordmark: {
        ...subHeadingBold(),
        marginLeft: 16,
    },
    nav: {
        display: 'flex',
        '& > *':{
            marginLeft: 8,
        },
        '& > *:first-child':{
            marginLeft: 0,
        },
    },
    link: {
        '&:focus': {
            border: 'none',
            outline: 'none',
        }
    },
    mobileNav: {
        display: 'none',
    },
    mobileTray: {
        display: 'none',
        backgroundColor: light,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: 24,
    },
    mobileTrayIn: {
        display: 'flex',
    },
    spacer: {
        height: 107,
    },
    '@media (max-width: 658px)': {
        content: {
            padding: 16,
            width: 'calc(100% - 32px)',
        },
        mobileNav: {
            display: 'block',
        },
        desktopNav: {
            display: 'none',
        },
        wordmark: {
            display: 'none',
        }
    }
};
