import { body, offWhite } from "../../designSystem";

export default {
    page: {
        backgroundColor: offWhite,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        ...body(),
        overflowX: 'hidden',
    },
};
