import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import styles from './styles';

class GlyphWrapper extends React.PureComponent {
    render() {
        const {
            children,
            className,
            iconSize,
            managedClasses,
            onClick,
            theme,
        } = this.props;

        const sizes = {
            24: 40,
            20: 32,
        };

        return (
            <button
                className={`${managedClasses[theme]} ${className}`}
                onClick={onClick}
                style={{
                    width: sizes[iconSize],
                    height: sizes[iconSize],
                    borderRadius: sizes[iconSize] / 2,
                }}
                type="button"
            >
                {children}
            </button>
        );
    }
};

GlyphWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
    className: PropTypes.string,
    iconSize: PropTypes.oneOf([24, 20]),
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
    onClick: PropTypes.func,
    theme: PropTypes.string,
};

GlyphWrapper.defaultProps = {
    children: {},
    className: '',
    iconSize: 24,
    theme: 'clear',
    onClick: undefined,
};

export default manageJss(styles)(GlyphWrapper);
