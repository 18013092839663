import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import styles from './styles';

class ActionButton extends React.PureComponent {
    render() {
        const {
            children,
            managedClasses,
            onClick,
            theme,
        } = this.props;

        return (
            <button
                className={managedClasses[theme]}
                onClick={onClick}
                type="button"
            >
                {children}
            </button>
        );
    }
};

ActionButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
    onClick: PropTypes.func,
    theme: PropTypes.string,
};

ActionButton.defaultProps = {
    children: {},
    theme: 'clear',
    onClick: undefined,
};

export default manageJss(styles)(ActionButton);
