import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyAvmrTQBeyD1JGWySoCxAl6PVh1L80olaw",
    authDomain: "brewday-co.firebaseapp.com",
    databaseURL: "https://brewday-co.firebaseio.com",
    projectId: "brewday-co",
    storageBucket: "brewday-co.appspot.com",
    messagingSenderId: "1023875527741",
    appId: "1:1023875527741:web:45d75340b7184c068a2654",
    measurementId: "G-PMLMH68YFB"
};

firebase.initializeApp(firebaseConfig);

export default firebase;