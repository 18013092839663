import { 
    bodyBold, 
    cardRest, 
    light, 
    medium, 
    offWhite, 
    strawberry,
    water,
} from "../../designSystem";

export default {
    actionButton: {
        transition: 'all cubic-bezier(.5,.5,.4,1) 150ms',
        border: '2px solid transparent',
        outline: 'none',
        background: 'none',
        ...bodyBold(),
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '6px 14px', // subtract border
        borderRadius: '16px',
        '&:hover': {
            cursor: 'pointer',
            ...cardRest(),
        },
        '&:focus': {
            outline: 'none',
        },
        '&:focus-visible': {
            border: `2px solid ${water()}`,
        },
    },
    clear: {
        composes: '$actionButton',
        '&:hover': {
            border: `2px solid ${medium()}`,
            boxShadow: 'none',
        }
    },
    primary: {
        composes: '$actionButton',
        backgroundColor: strawberry,
        border: `2px solid ${strawberry()}`,
        color: light,
    },
    secondary: {
        composes: '$actionButton',
        backgroundColor: offWhite,
        border: `2px solid ${offWhite()}`,
    },
};
