import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import styles from './styles';

class LoadingSpinner extends React.PureComponent {
    render() {
        const {
            className,
            managedClasses,
        } = this.props;

        return (
            <div className={`${managedClasses.spinner} ${className}`} />
        ); 
    }
};

LoadingSpinner.propTypes = {
    className: PropTypes.string,
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

LoadingSpinner.defaultProps = {
    className: '',
};

export default manageJss(styles)(LoadingSpinner);
