import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import { Link } from 'react-router-dom';
import styles from './styles';

class Footer extends React.PureComponent {
    render() {
        const {
            className,
            managedClasses,
        } = this.props;

        return (
            <div className={`${managedClasses.footer} ${className}`}>
                <div className={managedClasses.nav}>
                    <Link to="/about">About</Link>
                    <a href="mailto:support@brewday.co">Contact</a>
                    <Link to="/terms">Terms of service</Link>
                    <Link to="/privacy">Privacy policy</Link>
                    <Link to="/cookies">Cookie policy</Link>
                </div>
                <div className={managedClasses.legal}>
                    Copyright © Brewday 2020. All rights reserved. <br />
                    Brewday™ is a trademark of Cody Sorgenfrey.
                </div>
            </div>
        );
    }
};

Footer.propTypes = {
    className: PropTypes.string,
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

Footer.defaultProps = {
    className: '',
};

export default manageJss(styles)(Footer);
