import { 
    body, offWhite, water,
} from "../../designSystem";

export default {
    input: {
        ...body(),
        border: `2px solid ${offWhite()}`,
        outline: 'none',
        boxShadow: 'none',
        WebkitAppearance: 'none',
        backgroundColor: offWhite,
        borderRadius: 16,
        padding: '2px 14px', // subtract border
        '&:focus':{
            border: `2px solid ${water()}`,
        }
    }
};