import { bodyBold, caption, headingBold, water } from "../../designSystem";

export default {
    pageContent: {
        padding: 32,
        width: 'calc(100% - 64px)',
        maxWidth: 512,
    },
    title: {
        ...headingBold(),
        margin: 0,
    },
    entry: {
        marginTop: 32,
        maxWidth: 512,
    },
    question: {
        ...bodyBold(),
        marginBottom: 8,
    },
    answer: {
        ...caption(),
        marginBottom: 16,
        '& a': {
            textDecoration: 'none',
            color: water,
        },
        '& b': {
            ...bodyBold(),
        },
    },
};