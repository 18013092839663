import React from 'react';
import PropTypes from 'prop-types';
import manageJss from '@microsoft/fast-jss-manager-react';
import { Link } from 'react-router-dom';
import styles from './styles';
import Modal from '../../components/Modal';

class MobileNav extends React.PureComponent {
    render() {
        const {
            managedClasses,
        } = this.props;

        return (
            <Modal>
                <div className={managedClasses.logo}>
                    <svg fill="none" height="43" viewBox="0 0 33 43" width="33" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                            <path clipRule="evenodd" d="M5.97352 43C2.93275 42.9869 0.426001 40.7118 0.0489993 37.7704C0.0326435 37.7551 0.0163104 37.7398 0 37.7245V6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6V42.976V43H5.97352Z" fill="#00C8FF" fillRule="evenodd" />
                            <path d="M14 42.9589C20.1595 42.4508 25 37.2907 25 31C25 24.7093 20.1595 19.5492 14 19.0411V42.9589Z" fill="#F4592A" />
                            <path d="M23.9255 39.7549C25.8492 37.3573 27.0001 34.3131 27.0001 31C27.0001 23.6043 21.2653 17.5479 14.0001 17.0352V6.00001C14.0001 4.94076 13.7942 3.92959 13.4203 3.00433C24.272 3.22801 33.0001 12.0947 33.0001 23C33.0001 30.014 29.3895 36.1847 23.9255 39.7549Z" fill="#333333" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect fill="white" height="43" width="33" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className={managedClasses.wordmark}>
                        Brewday
                    </div>
                </div>
                <div className={managedClasses.mainNav}>
                    <Link
                        className={managedClasses.link}
                        to="/"
                    >
                        Home
                    </Link>
                    <Link
                        className={managedClasses.link}
                        to="/faq"
                    >
                        FAQs
                    </Link>
                </div>
                <div className={managedClasses.secNav}>
                    <Link
                        className={managedClasses.link}
                        to="/about"
                    >
                        About
                    </Link>
                    <a
                        className={managedClasses.link}
                        href="mailto:support@brewday.co"
                    >
                        Contact
                    </a>
                    <Link
                        className={managedClasses.link}
                        to="/terms"
                    >
                        Terms of service
                    </Link>
                    <Link
                        className={managedClasses.link}
                        to="/privacy"
                    >
                        Privacy policy
                    </Link>
                    <Link
                        className={managedClasses.link}
                        to="/cookies"
                    >
                        Cookie policy
                    </Link>
                </div>
            </Modal>
        );
    }
};

MobileNav.propTypes = {
    managedClasses: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default manageJss(styles)(MobileNav);
