import { 
    caption,
    dark, 
    legal, 
    medium, 
} from "../../designSystem";

export default {
    footer: {
        marginTop: 'auto',
        color: dark,
        width: 'calc(100% - 64px)',
        maxWidth: 1440-64,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 32,
    },
    nav: {
        display: 'flex',
        '& > *':{
            marginLeft: 16,
        },
        '& > *:first-child':{
            marginLeft: 0,
        },
        '& > a':{
            ...caption(),
            color: medium,
            textDecoration: 'none',
            '&:hover': {
                color: dark,
            },
        },
    },
    legal: {
        ...legal(),
        color: medium,
        textAlign: 'right',
    },
    '@media (max-width: 658px)': {
        footer: {
            padding: 24,
            width: 'calc(100% - 48px)',
        },
        nav: {
            display: 'none',
        },
        legal: {
            textAlign: 'left',
        }
    },
};
