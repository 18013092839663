import {
    strawberry,
    subHeadingBold,
} from '../../designSystem';

export default {
    desc: {
        width: '100%',
        marginBottom: 24,
    },
    title: {
        ...subHeadingBold(),
        marginBottom: 8,
        width: '100%',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    textInput: {
        alignSelf: 'stretch',
        marginBottom: 8,
    },
    error: {
        marginBottom: 8,
        marginLeft: 16,
        color: strawberry,
    },
    buttonWithSpinner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    spinner: {
        marginLeft: 8,
    },
};
